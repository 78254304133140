import type { Plan } from 'features/signup/signup-types';
import { PlanType } from 'features/signup/signup-types';

export const FREE_PRICE = 'Free';

export const TRIAL_PLAN: Plan = {
  features: [
    '14 days free',
    '500 queries',
    'Point and click training',
    'Authenticated extraction',
    'Interactive extraction',
    'Download images and files',
    'Capture screenshots',
    'CSV, Excel, JSON output',
    'Reporting',
    'API access and Webhooks',
    'E-mail and ticket support',
  ],
  label: 'Free Trial of Standard Plan (14 days)',
  price: FREE_PRICE,
  queries: 500,
  value: PlanType.TRIAL,
};

export const PLANS: Record<PlanType, Plan> = {
  [PlanType.TRIAL]: TRIAL_PLAN,
  [PlanType.STARTER]: {
    features: [
      '5,000 queries',
      'Point and click training',
      'Authenticated extraction',
      'Interactive extraction',
      'Download images and files',
      'Overages at $0.056/query',
      'CSV, Excel, JSON output',
      'Reporting',
      'E-mail and ticket support',
    ],
    label: 'Starter Plan Subscription',
    price: '$399 / month',
    queries: 5_000,
    value: PlanType.STARTER,
  },
  [PlanType.STANDARD]: {
    features: [
      '20,000 queries',
      'Point and click training',
      'Authenticated extraction',
      'Interactive extraction',
      'Download images and files',
      'Overages at $0.040/query',
      'Capture screenshots',
      'CSV, Excel, JSON output',
      'Access to premium proxies*',
      'Reporting',
      'API access and Webhooks',
      'E-mail, ticket and chat support',
    ],
    label: 'Standard Plan Subscription',
    price: '$599 / month',
    queries: 20_000,
    value: PlanType.STANDARD,
  },
  [PlanType.ADVANCED]: {
    features: [
      '50,000 queries',
      'Point and click training',
      'Authenticated extraction',
      'Interactive extraction',
      'Download images and files',
      'Overages at $0.029/query',
      'Capture screenshots',
      'CSV, Excel, JSON output',
      'Access to premium proxies*',
      'Reporting',
      'API access and Webhooks',
      'E-mail, ticket, chat and phone support',
    ],
    label: 'Advanced Plan Subscription',
    price: '$1099 / month',
    queries: 50_000,
    value: PlanType.ADVANCED,
  },
};

export const VALID_PLANS = Object.keys(PLANS);

export const SELECT_PLAN_ITEMS = Object.values(PLANS);
