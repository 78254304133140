import type { FormEvent, RefObject } from 'react';

import type { SignupForm } from '@import-io/types';
import type { CardElementChangeEvent } from '@recurly/react-recurly';
import type { FormInstance } from 'antd/es/form/hooks/useForm';
import type { MessageInstance } from 'antd/lib/message/interface';

export enum PlanType {
  TRIAL = 'free_trial_2303',
  STARTER = 'starter_2303',
  STANDARD = 'standard_2303',
  ADVANCED = 'advanced_2303',
}

interface RecurlyCardField {
  empty: boolean;
  focus: boolean;
  valid: boolean;
}

interface RecurlyCard extends RecurlyCardField {
  brand: string;
  cvv: RecurlyCardField;
  expiry: RecurlyCardField;
  firstSix: string;
  lastFour: string;
  number: RecurlyCardField;
}

export interface SignupFormData extends SignupForm {
  card?: RecurlyCard;
}

export interface Plan {
  features: string[];
  label: string;
  price: string;
  queries: number;
  value: PlanType;
}

export interface SignupHeaderProps {
  error: string;
  isFree: boolean;
}

export interface PaymentCardProps {
  isFree: boolean;
  setFieldValue: (name: string, value: CardElementChangeEvent) => void;
}

export interface CardInputProps {
  onChange: (value: CardElementChangeEvent) => void;
  onValidChange: (isValid: boolean) => void;
}

export interface PlanSelectorProps {
  features: string[];
  onChange: (value: PlanType) => void;
  price: string;
}

export interface SubmitButtonProps {
  disabled: boolean;
  loading: boolean;
}

export interface UseSubmitForm {
  form: FormInstance<SignupFormData>;
  formRef: RefObject<HTMLFormElement>;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
}

export interface UseFinishParams {
  formRef: RefObject<HTMLFormElement>;
  messageApi: MessageInstance;
}

export interface UseFinish {
  error: string;
  isLoading: boolean;
  isSuccess: boolean;
  onFinish: (values: SignupFormData) => void;
}

// TODO: move types to some common UI package
export enum RecurlyErrorCategory {
  SOFT = 'SOFT',
}

export enum RecurlyErrorCode {
  DECLINED = 'DECLINED',
}

export enum RecurlyErrorStatus {
  ERROR = 'ERROR',
}

export interface SignupErrorBody {
  error?: string;
  message?: string;
  recurlyError?: {
    customerMessage: string;
    errorCategory: RecurlyErrorCategory;
    errorCode: RecurlyErrorCode;
    status: RecurlyErrorStatus;
  };
}
